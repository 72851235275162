define("ember-model-validator/decorators/object-validator", ["exports", "@ember/array", "@ember/object", "@glimmer/tracking", "ember-model-validator/decorators/core-validator"], function (_exports, _array, _object, _tracking, _coreValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function objectValidator(Class) {
    var _class, _class2, _descriptor;
    let ObjectValidator = (0, _coreValidator.default)(_class = (_class2 = class ObjectValidator extends Class {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "errors", _descriptor, this);
      }
      clearErrors() {
        (0, _object.set)(this, 'errors', {
          errorsFor: this.errors.errorsFor,
          _errors: (0, _array.A)()
        });
        (0, _object.set)(this, 'validationErrors', {});
        (0, _object.set)(this, 'isValidNow', true);
      }
      pushErrors(errors) {
        let errorsObj = {};
        for (let attribute in errors) {
          let messages = errors[attribute];
          if (!errorsObj[attribute]) {
            errorsObj[attribute] = (0, _array.A)([]);
          }
          errorsObj[attribute].push({
            message: messages.flat()
          });
          (0, _object.set)(this, 'errors', {
            ...this.errors,
            ...errorsObj,
            _errors: [...this.errors._errors, {
              attribute,
              message: messages.flat()
            }]
          });
        }
      }
      errorsFor(attribute) {
        return this.errors.errorsFor(attribute);
      }
      _modelRelations() {}
    }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "errors", [_tracking.tracked], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: function () {
        return {
          _errors: (0, _array.A)(),
          errorsFor(attribute) {
            return (0, _array.A)(this._errors.filter(error => error.attribute === attribute));
          }
        };
      }
    })), _class2)) || _class;
    return ObjectValidator;
  }
  var _default = _exports.default = objectValidator;
});